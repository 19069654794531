<template>
    <div>
        <v-card>
            <v-card-title class="title">
                <v-flex xs4 sm4>
                    <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                              label="Grade" v-model="grade" dense outlined/>
                </v-flex>
                <v-flex xs2>
                    <search-button style="" :disabled="!grade" permission="billing-report-collection"
                                   @action="get()">
                        Search
                    </search-button>
                </v-flex>

                <v-spacer></v-spacer>
                <print-buttons :passAuthToReport="true" v-if="downloadUrl && form.items.data.length > 0"
                               :download-url="downloadUrl"
                               :pdf="true" :excel="true" labelExcel="Download Excel" labelPdf="Download Pdf"></print-buttons>
            </v-card-title>
        </v-card>
        <v-data-table :headers="headers" hide-default-footer
                      :items="form.items.data" :search="search"
                      :options.sync="pagination" :loading="form.loading">
            <template  v-slot:item="{index, item}">
                <tr>
                    <td class="text-xs-left">{{ index +1}}</td>
                    <td class="text-xs-center">{{item.bill_month}}</td>
                    <td class="text-xs-center">
                        {{item.sum_amount.currency()}}
                    </td>
                </tr>
            </template>

            <template v-slot:body.append v-if="form.items.data.length > 0">
                <tr>
                    <th :colspan="2" class="text-xs-center">Total</th>
                    <th class="text-xs-center">{{(totalBillAmount).numberFormat()}}</th>
                </tr>
            </template>
<!--            <template slot="footer" v-if="form.items.data.length > 0">-->
<!--                <tr>-->
<!--                    <th :colspan="2" class="text-xs-center">Total</th>-->
<!--                    <th class="text-xs-center">{{(totalBillAmount).numberFormat()}}</th>-->
<!--                </tr>-->
<!--            </template>-->
        </v-data-table>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';
    import {validateDate} from "../../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        mixins: [Mixins],
        data: () => ({
            gradeLoading: false,
            form: new Form({}, '/api/report/billing/monthly-bill-collection'),
            search: null,
            totalSum: 0,
            studentsCollection: [],
            sdate: nd.format('YYYY-MM-DD'),
            // sdate: '2076-02-08',
            headers: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                {text: 'Billing Month', align: 'centre', value: 'bill_date', sortable: false},
                {text: 'Amount', align: 'centre', value: 'sum_amount', sortable: false},

            ],
            grades: [],
            grade: 'all',
            sections: [],
            section: '',
            collection: [],
            extra: [],
            advance: [],
            totalBillAmount: 0,
            downloadUrl: '',

        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'grade': function (value) {
                this.form.items.data = [];
            },
        },

        methods: {

            get(params) {
                let extraParams = 'gradeId=' + this.grade;
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                if (this.grade) {
                    this.form.get(null, query).then(({data}) => {
                        this.totalBillAmount = 0;
                        data.data.map(r => {
                            this.totalBillAmount += r.sum_amount;
                        });
                        this.pagination.totalItems = data.meta.total;
                        this.downloadUrl = data.download_url;
                    })
                }
            },

            save() {
                this.form.batchId = this.batch.id;
                this.form.grade_id = this.grade;
                this.form.bill_month = this.month;
                this.form.store()
            },

            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true').then(({data}) => {
                    this.grades = [{value: 'all', text: 'ALL'}]
                    data.data.map(item => {
                        this.grades.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },

            getSection() {
                if (this.grade) {
                    this.$rest.get('/api/section?grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
                }
            },
            dateFormat(data) {
                if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
                else return data;
            },
            monthName(month) {
                switch (month) {
                    case '01':
                        return "Baishakh";
                    case '02':
                        return "Jestha";
                    case '03':
                        return "Ashadh";
                    case '04':
                        return "Shrawan";
                    case '05':
                        return "Bhadra";
                    case '06':
                        return "Ashoj";
                    case '07':
                        return "Kartik";
                    case '08':
                        return "Mangsir";
                    case '09':
                        return "Poush";
                    case '10':
                        return "Magh";
                    case '11':
                        return "Falgun";
                    case '12':
                        return "Chaitra";
                }
            }

        }
    }
</script>
<style lang="scss">
    .completed {
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        margin: 5px;
    }

    .bred {
        background: #ff5252;
    }

    .bgreen {
        background: #4caf50;
    }

    .byellow {
        background: #ff9800;
    }
</style>